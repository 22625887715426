import React from 'react'

const Title = () => {
  return (
    <div className="text-center w-100 mt-3">
      <h4 className="font-weight-light mb-0">Varelotteriets</h4>
      <h1 className="font-weight-bold ">Millionærliga</h1>
    </div>
  )
}

export default React.memo(Title)
