import React from 'react'

import './spinner.scss'

const Spinner = ({ size = 6, style = {} }) => {
  //const { error } = props
  //const statusTxt = !error ? 'Getting data...' : error

  return <div className="loader" style={{ fontSize: size, ...style }}></div>
}

export default Spinner

/*

 <div className="h-100 " style={{ background: 'red' }}>
      {!error ? (
        <div className="loader"></div>
      ) : (
        <div className="w-100 d-flex flex-column justify-content-center align-items-center " style={{ padding: 20 }}>
          <div className="w-50">
            <h1>An error happend:</h1>
            <p>{statusTxt}</p>
          </div>
        </div>
      )}
    </div>
 */
