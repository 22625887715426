import React, { useState, useEffect, useRef } from 'react'

//import { currentUser } from './injectedState'
import Home from './components/screens/Home'
import Splash from './components/screens/Splash'
import NavBar from './components/UI/Navbar'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'

import { AnimatedRoute } from './components/AnimatedRoute'
import { BATTLE, GLOBAL_RANK, HOME, LIGA, MORE, PERMISSION, TERMS, USER, WEEKLY, RANK, CHANCEN } from './routes'
import Weekly from './pages/Weekly'
import Liga from './pages/Liga'
import Sprint from './pages/Sprint'
import User from './pages/user/User'
import More from './pages/More'
import HasProfileRoute from './components/HasProfileRoute'
import GlobalRank from './pages/GlobalRank'
import Battle from './pages/Battle'
import PermissionScore from './pages/PermissionScore'
import Terms from './pages/Terms'
import Signup from "./components/Signup";
import Chancen from "./pages/Chancen";

import { userStore } from './store/UserStore'

const App = () => {
  // const profile = userStore((state) => state.profile)
  // const getUserFromMappId = userStore((state) => state.getUserFromMappId)
  const postMappId = userStore((state) => state.postMappId)

  const [campaignActive, setCampaignActive] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const isInitialMount = useRef(true)
  useEffect(() => {
    if (isInitialMount.current) {
        isInitialMount.current = false
        console.log ("Varelotteriet -> Version 2.2.2");
    }
  }, [])

  useEffect(() => {
      postMappId('/GetCampaignConfig').then((res) => {
        // console.log('App:', res)
        setStartDate(new Date(res.StartTimestamp))
        setEndDate(new Date(res.EndTimestamp))

        // var testStart = new Date("2021-06-18T00:00:00")

        var today = new Date()
        var startDate = new Date(res.StartTimestamp)
        startDate.setDate(startDate.getDate() - 1) // We wanna make it possible for sellers to login the day before start
        var endDate = new Date(res.EndTimestamp)

        // If today is later than start && today is before end
        if (Date.parse(today) >= Date.parse(startDate) && Date.parse(today) <= Date.parse(endDate)) {
          // console.log('Today is between start and end date!!!')
          setCampaignActive(true)
        }
        else {
          // console.log('Today is NOT between start and end date!!!')
          setCampaignActive(false)
        }
      })
  }, [postMappId])

  return (
    <Router>
      <div className="position-relative" style={{ height: '100%' }}>
        <AnimatedRoute>
          <Route exact path="/">
            <Splash campaignActive={campaignActive} startDate={startDate} endDate={endDate}></Splash>
          </Route>
          
          <Route exact path='/terms-test'>
              <Terms />
          </Route>

          {/* <HasProfileRoute exact path="/testt">
            <h1>asdads</h1>
          </HasProfileRoute> */}

          <HasProfileRoute exact path={HOME.to}>
            <Home />
          </HasProfileRoute>

          <HasProfileRoute exact path={WEEKLY.to}>
            <Weekly />
          </HasProfileRoute>

          <HasProfileRoute exact path="/sprint">
            <Sprint day="Torsdag"/>
          </HasProfileRoute>

          <HasProfileRoute exact path={LIGA.to}>
            <Liga></Liga>
          </HasProfileRoute>

          <HasProfileRoute exact path={TERMS.to}>
            <Terms></Terms>
          </HasProfileRoute>

          <HasProfileRoute exact path={GLOBAL_RANK.to}>
            <GlobalRank></GlobalRank>
          </HasProfileRoute>

          <HasProfileRoute exact path={USER.to}>
            <User></User>
          </HasProfileRoute>

          <HasProfileRoute exact path={MORE.to}>
            <More></More>
          </HasProfileRoute>

          <HasProfileRoute exact path={RANK.to}>
            <div>Test Rank Side</div>
          </HasProfileRoute>

          <HasProfileRoute exact path={CHANCEN.to}>
            <Chancen />
          </HasProfileRoute>

          <HasProfileRoute exact path={BATTLE.to}>
            <Battle></Battle>
          </HasProfileRoute>

          <HasProfileRoute exact path={PERMISSION.to}>
            <PermissionScore></PermissionScore>
          </HasProfileRoute>

          <Route exact path="/signup">
            <Signup></Signup>
          </Route>

          <Redirect to={'/'} />
        </AnimatedRoute>
      </div>
      {
        campaignActive ? 
          (<NavBar />)
        :
          null
      }
      {/* <NavBar /> */}
    </Router>
  )
}

export default App
