import React from 'react'
import SignupForm from './SignupForm'

export default function Signup() {
  const [signedUp, setSignedUp] = React.useState(false)

  const parameters = parseQueryString(window.location.search.substring(1));

  // Email is actually not an email, it is a MappID.
  const email = parameters.mapp || false

  if (!email || email.length === 0) {
    return (
      <div className="w-full h-full p-2 flex justify-center items-center text-black flex-col text-center">
        <h1 className="my-4 text-2xl">Hov der er noget i vejen med dit link</h1>
      </div>
    )
  }

  return (
    <React.Fragment>
      {
        !signedUp ? (
          <div className="w-full h-full p-2 flex justify-center items-center text-black flex-col text-center">
            <h1 className="my-4 text-2xl">Tilmeld dig forhandlernes Millionærliga!</h1>
            {/* <h2 className="italic">Indtast dit mobilnr. herunder og vær' med i konkurrencen om præmier for mere end 50.000 kroner!</h2> */}
            <h2 className="italic">Indtast dit mobilnr. herunder og vær' med i konkurrencen om fede præmier hver uge</h2>
            {/* <SignupForm email={email} /> */}
          </div>
        ) : null
      }
      <div className="w-full h-full p-2 flex justify-center items-center text-black flex-col text-center">
        <SignupForm email={email} setSignedUp={setSignedUp}/>
      </div>
    </React.Fragment>
  )
}

function parseQueryString(query) {
  let params = query.split("&")
  let query_string = {}
  for (let i = 0; i < params.length; i++) {
    let pair = params[i].split("=")
    let key = decodeURIComponent(pair[0])
    let value = decodeURIComponent(pair[1])
    // If first entry with this name
    if (typeof query_string[key] === "undefined") {
      query_string[key] = decodeURIComponent(value)
      // If second entry with this name
    } else if (typeof query_string[key] === "string") {
      let arr = [query_string[key], decodeURIComponent(value)]
      query_string[key] = arr
      // If third or later entry with this name
    } else {
      query_string[key].push(decodeURIComponent(value))
    }
  }
  return query_string
}
