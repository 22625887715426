import React from 'react'
import userSvg from '../../assets/min_side.svg'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { HOME, SPLASH, TERMS, USER } from '../../routes'
import { SHOW_MY_PAGE } from '../../constants'

//const Navbar = ({ screenState, navBarHeight, dispatchScreen, setShowTermsPopup, showTermsPopup }) => {
const Navbar = () => {
  const location = useLocation()

  const lineHeight = 4
  const linePadding = 5

  if (location.pathname === SPLASH.to) {
    return (
      <div className="w-100 position-fixed bg-green " style={{ bottom: 0, height: 40 }}>
        <div className="d-flex justify-content-center w-100" style={{ marginTop: -50 }}>
          <Link
            to={HOME.to}
            className="rounded-circle bg-lime position-relative text-decoration-none"
            style={{ border: '8px solid #6EB400', width: '5rem', height: '5rem' }}
          >
            <h5 className="mb-0 mt-3 text-center" style={{ color: 'black' }}>
              <b>OK</b>
            </h5>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className="w-100 position-fixed bg-green " style={{ bottom: 0, height: 40 }}>
      <div className="d-flex justify-content-center w-100" style={{ marginTop: -50 }}>
        {/*SHOW WHEN ON HOME*/}
        {location.pathname !== HOME.to && (
          <Link
            to={HOME.to}
            className="rounded-circle bg-lime"
            style={{ border: '8px solid #6EB400', width: '5rem', height: '5rem' }}
          >
            <div className="p-3 w-100 h-100 d-flex flex-wrap justify-content-between">
              <div className="square square--mini bg-darkGreen"></div>
              <div className="square square--mini bg-orange"></div>
              <div className="square square--mini bg-red"></div>
              <div className="square square--mini bg-purple"></div>
            </div>
          </Link>
        )}

        {location.pathname === HOME.to && (
          <Link
            to={TERMS.to}
            className="rounded-circle bg-lime position-relative"
            style={{ border: '8px solid #6EB400', width: '5rem', height: '5rem' }}
          >
            <div
              className="w-100  position-absolute"
              style={{ top: '1.4rem', height: '1.7rem', paddingRight: 20, paddingLeft: 20 }}
            >
              <div className="bg-dark w-100 " style={{ marginBottom: linePadding, height: lineHeight }}></div>
              <div className="bg-dark w-100" style={{ marginBottom: linePadding, height: lineHeight }}></div>
              <div className="bg-dark w-100" style={{ marginBottom: linePadding, height: lineHeight }}></div>
            </div>
          </Link>
        )}
        {SHOW_MY_PAGE ? (
          <Link
            to={USER.to}
            className="ml-2 rounded-circle bg-lime position-relative"
            style={{ border: '8px solid #6EB400', width: '5rem', height: '5rem' }}
          >
            <div
              className="position-absolute"
              style={{ left: '1.15rem', top: '1rem', width: '1.7rem', height: '1.7rem' }}
            >
              <img className="w-100" src={userSvg} alt="" />
            </div>
          </Link>
        ) : null}
      </div>
    </div>
  )
}

export default React.memo(Navbar)
