const API_KEY = '6RkmiveGfQv82CU'
// const BASE_URL = 'https://vlml.ambitioncloud.dk'
const BASE_URL = process.env.REACT_APP_BASE_URL

export const get = (path, query) => {
  const url = path + query
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json()
    })
    .then((res) => res)
    .catch((err) => {
      console.log(' API > err = ', err)
      return err
    })
}

export const post = (endpoint, data = {}) => {
  const p = BASE_URL + endpoint

  return fetch(p, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-ApiKey': API_KEY,
      //Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
  })
    .then(status)
    .then((res) => {
      // console.log('res1:', res)
      return res.json()
    })
    .then((res) => {
      // console.log('res2:', res)
      return res
    })
    .catch((err) => {
      // console.log('err:', err)
      return Promise.reject(err)
    })
}

function status(res) {
  if (!res.ok) {
    return Promise.reject()
  }
  return res
}
