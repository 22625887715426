import React from 'react'
import { PLACEMENT_WIDTH } from '../constants'

const ListHeader = ({
  title = 'asdasd',
  byline = 'asdasdds',
  background = 'red',
  icon = '',
  textColor = 'white',
  placement = 'Placering',
  retailer = 'Forhandler',
  soldtickets = 'Solgte lodder',
  showListLabels = true,
}) => {
  return (
    <>
      <div className="row" style={{ background: background }}>
        <div className="col-12 d-flex">
          {icon && (
            <div className="d-flex pr-3 align-items-center">
              <img className="" style={{ width: 50, height: 50 }} src={icon} alt="" />
            </div>
          )}

          <div className="h-100 pt-2 d-flex flex-column justify-content-center">
            <h4 className="mb-0" style={{ color: textColor }}>
              {title}
            </h4>
            <p className="mt-n2" style={{ color: textColor }}>
              {byline}
            </p>
          </div>
        </div>
      </div>

      {showListLabels && (
        <div className="row" style={{}}>
          <div className="col-12 px-0 " style={{ background: 'black' }}>
            <div className="d-flex justify-content-between py-1 px-3 bg-black w-screen">
              <div className="d-flex">
                <p className="mr-2 p-small text-lime text-sm mb-1" style={{ width: PLACEMENT_WIDTH }}>
                  {placement}
                </p>
                <p className="p-small text-sm text-lime mb-1">{retailer}</p>
              </div>
              <p className="p-small text-sm text-lime mb-1">{soldtickets}</p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default ListHeader
