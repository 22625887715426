import React from 'react'
import ListHeader from '../components/ListHeader'

import { Link } from 'react-router-dom'
import { BATTLE, GLOBAL_RANK, PERMISSION } from '../routes'
import xtra from '../assets/xtra.svg'
import placementIcon from '../assets/placering.svg'
import brancheIcon from '../assets/branche.svg'
import permissionIcon from '../assets/permission.svg'

const More = (props) => {
  return (
    <div className="container-fluid h-100">
      <ListHeader showListLabels={false} title="Xtra" byline="Flere konkurrencer " icon={xtra} background={'#E29C00'} />
      <div className="row ">
        <div className="col-12 mb-4 px-4 mt-4 ">
          <Link to={GLOBAL_RANK.to} className="bg-white d-flex p-3" style={{ borderRadius: 4 }}>
            {/*<div className="rounded-circle" style={{ width: 50, height: 50, background: '#dfdfdf' }}></div>*/}
            <img src={placementIcon} alt="" />
            <div className="pl-4">
              <p className="mb-0 text-darkGreen p-big">Samlet stilling</p>
              <p className="mb-0 p-small mt-n2 text-darkGreen" style={{}}>
                solgte lodder i alt
              </p>
            </div>
          </Link>
        </div>

        <div className="col-12 mb-4 px-4">
          <Link to={BATTLE.to} className="bg-white d-flex p-3" style={{ borderRadius: 4 }}>
            <img src={brancheIcon} alt="" />
            <div className="pl-3">
              <p className="mb-0 text-darkGreen p-big">BrancheBattle</p>
              <p className="mb-0 p-small mt-n2 mt-n2 text-darkGreen" style={{}}>
                Spillerregistreringer per branche
              </p>
            </div>
          </Link>
        </div>

        <div className="col-12 mb-4 px-4">
          <Link to={PERMISSION.to} className="bg-white d-flex p-3" style={{ borderRadius: 4 }}>
            <img src={permissionIcon} alt="" />
            <div className="pl-3">
              <p className="mb-0 text-darkGreen p-big">Din 'skrabe-score'</p>
              <p className="mb-0 p-small mt-n2 text-darkGreen" style={{}}>
                Se hvor godt du ligger
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
export default More
