import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import App from './App'
import reportWebVitals from './reportWebVitals'

import './styles/styles.scss'
//import Main from './Main'

if (!window.dataLayer) {
  window.dataLayer = []
}

Sentry.init({ dsn: 'https://be6e9cb87e1b495a8ccc1390ba860b7d@o192165.ingest.sentry.io/240409' })

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
