import React, { useEffect, useState } from 'react'

import ListHeader from '../../components/ListHeader'
import { DARK_GREEN } from '../../constants'
import { userStore } from '../../store/UserStore'
import userSvg from '../../assets/min_side.svg'
import LeagueScore from './../user/LeagueScore'
// import permissionIcon from '../../assets/permission.svg'
import { post } from '../../service/API'

// import { ReactComponent as StarIcon } from '../../assets/star.svg'
import GlobalRankRow from './GlobalRankRow'
import ChancenRow from './ChancenRow'
import FiveInARow from './FiveInARow'
import ScratchScore from './ScratchScore'

const User = () => {
	// const [streak, setStreak]           = useState([])
	// const [daysLeft, setDaysLeft]       = useState({days:0, total:5})
	// eslint-disable no-unused-vars
	// eslint-disable-next-line no-unused-vars
	const [scratcScore, setScratcScore] = useState({perctageScore:'', placement:''})
	const postMappId                    = userStore((state) => state.postMappId)
	//const mappId = DEBUG ? 6110297485 : userStore((state) => state.mappId)
	const mappId                        = userStore((state) => state.mappId)
	// const profile                       = userStore((state) => state.profile)

	const [uiConfig, setUiConfig]		= useState([])

	useEffect(() => {
		post('/GetBusinessGlobalRank', {}).then((res) => {
			const scoreArr = res.resellerList.filter((item) => {
				return item.mappId === mappId
			})

			const score     = scoreArr.length === 1 ? scoreArr[0].permissionPercentage : 'ikke oplyst'
			const placement = scoreArr.length === 1 ? scoreArr[0].position_shared : 'ikke oplyst'
			setScratcScore({placement:placement, perctageScore:score})
		})
	}, [mappId, postMappId])

	// useEffect(() => {
	// 	postMappId('/GetCurrentResellerStreakState').then((res) => {
	// 		const count = res.streak_state.filter((item) => {
	// 			return item.highlight
	// 		})
	// 		setDaysLeft({days:count.length, total:res.streak_state.length})
	// 		setStreak(res.streak_state)
	// 	})
	// }, [postMappId])

    useEffect(() => {
        postMappId('/EnumUIElements').then((res) => {
            let myPageElements = []
			res.UIElementList.map((item, index) => {
				if (item.Id.startsWith('MyPage.') && item.Active === true) {
					myPageElements.push(item)
				}
				return null
			})
			setUiConfig(myPageElements)
			// console.log('UIElements:',myPageElements)
        })
    }, [postMappId])

	const getUIElement = (item, index) => {

		switch (item.Id) {
			case "MyPage.LeagueRank":
				return (
					<div className="row" style={{}} key={index}>
						<LeagueScore mappId={mappId}></LeagueScore>
					</div>
				)
			case "MyPage.GlobalRank":
				return (
					<GlobalRankRow mappId={mappId} key={index}></GlobalRankRow>
				)
			case "MyPage.PermissionRank":
				return (
					<ScratchScore key={index}/>
				)
			case "MyPage.Streak5":
				return (
					<FiveInARow key={index}/>
				)
				case "MyPage.Streak3":
					return (
						<ChancenRow key={index}/>
					)
			default:
				break
		}

		return null
	}

	return (
		<div className="container-fluid h-100">
			<ListHeader
				title="Din Side"
				byline="Få det samlede overblik"
				icon={userSvg}
				background={'#baea00'}
				textColor={DARK_GREEN}
				showListLabels={false}
			/>

			{
				uiConfig.map((item, index) => {
					return getUIElement(item, index)
				})
			}

			

			{/* OLD LeagueScore Component */}
			{/* <div className="row" style={{}}>
				<LeagueScore mappId={mappId}></LeagueScore>
			</div> */}

			{/* OLD Skrabe-Score */}
			{/* <div className="row" style={{}}>
				<div className="col-10 bg-white mx-auto mb-2 mt-3 d-flex p-1 " style={{borderRadius:4}}>
					<img className="pl-3" src={permissionIcon} alt=""/>

					<div className="ml-3">
						<p className="mb-0 text-darkGreen">'skrabe-score'</p>
						<p className="mb-0 mt-n2 text-darkGreen">Din score {scratcScore.perctageScore}%</p>
						<p className="mb-0 mt-n2 text-darkGreen">Du ligger på {scratcScore.placement}. plads</p>
					</div>
				</div>
			</div> */}


			{/* OLD 5-på-stribe */}
			{/* <div className="row" style={{}}>
				<div className="col-10 bg-white mx-auto mb-2 mt-2 py-2 px-1 " style={{borderRadius:4}}>
					<p className="mb-1 text-darkGreen text-center p-big  ">5 på stribe!</p>

					{daysLeft.total === daysLeft.days && (
						<p className="mb-0 mt-n2 text-darkGreen text-center px-2">
							Du er med i <i>lodtrækningen om {profile.price} kr.</i>
						</p>
					)}

					{daysLeft.total === daysLeft.days + 1 ? (
						<p className="mb-0 mt-n2 text-darkGreen text-center px-2">
							Sælger du lodder {daysLeft.total - daysLeft.days} dag mere, er du med
							<i>lodtrækningen om {profile.price} kr.</i>
						</p>
					) : (
						<p className="mb-0 mt-n2 text-darkGreen text-center px-2">
							Sælger du lodder de næste {daysLeft.total - daysLeft.days} dage, er du med i{' '}
							<i>lodtrækningen om {profile.price} kr.</i>
						</p>
					)}

					<div className="d-flex mt-2 justify-content-center">
						{streak.map((item, index) => {
							const color = item.highlight ? '#ffa200' : '#bababa'

							return (
								<div key={index} className="mx-1">
									<StarIcon style={{fill:color, width:35, height:35}}></StarIcon>
									<p className="text-center text-darkGreen font-weight-bold">{item.dayNameShort}</p>
								</div>
							)
						})}
					</div>
				</div>
			</div> */}

		</div>
	)
}
export default User
