import gold from './assets/trappe-gold.svg'
import silver from './assets/trappe-silver.svg'
import bronze from './assets/trappe-bronze.svg'

export const SHOW_MY_PAGE = process.env.REACT_APP_SHOW_MY_PAGE === 'YES'
export const DEBUG = process.env.REACT_APP_DEBUG === 'true'

export const DARK_RED = '#7C0202'
export const DARK_GREEN = '#164E08'
export const PURPLE = '#7B2D7E'
export const LIME = '#BAEA00'
export const YELLOW = '#E29C00'

// padding in list
export const PLACEMENT_WIDTH = 60

export const LIGA_TYPES = [{ title: 'Guld' }, { title: 'Sølv' }, { title: 'Bronze' }]

/**
 *
 * @param league_id - should be 1,2,3
 * @return a path to svg icon
 */

export const getLeagueIcon = (league_id) => {
  const leagueIconsArr = [gold, silver, bronze]
  const leagueIndex = league_id ? Number(league_id) - 1 : 2
  // const leagueIndex = 3
  return leagueIconsArr[leagueIndex]
}

/**
 *
 * @param league_id - should be 1,2,3
 * @return {string}
 */
export const getLeagueTitle = (league_id) => {
  const leagueIndex = league_id ? Number(league_id) - 1 : 2
  return LIGA_TYPES[leagueIndex] ? LIGA_TYPES[leagueIndex].title : ''
}
