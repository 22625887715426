import React, { useEffect, useRef, useState } from 'react'
import '../components/list-item.scss'
import { PURPLE } from '../constants'

import ListHeader from '../components/ListHeader'
import weekIcon from '../assets/ugens.svg'
import { userStore } from '../store/UserStore'
import Spinner from '../components/spinner/Spinner'


const Sprint = (props) => {
  const [users, setUsers] = useState([])
  const [initialData, setInitialData] = useState([])
  const [size, setSize] = useState(20)
  const postMapId = userStore((state) => state.postMappId)
  const mappId = userStore((state) => state.mappId)
  const userRef = useRef(null)
  const windowRef = useRef(null)
  const [loading, setLoading] = useState(false)


  const gotoRef = (pos) => {
    window.scrollTo(0, pos)
  }

  // Detect if user is scrolled to bottom and load more data
  // const handleScroll = (e) => {
  //   const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  //   if (bottom) { 
  //     setSize(size + 20) 
  //   }
  // }

  // Get resellers and save the data into state
  useEffect(() => {
    setLoading(true)

    postMapId('/GetSprintRank').then((res) => {
      // eslint-disable-next-line array-callback-return
      res.resellerList.map((item, index) => {
        if(item.mappId === mappId) return setSize(index+15)
      })
      setInitialData(res.resellerList)
      
      setLoading(false)
      
      if (userRef.current) {
        gotoRef(userRef.current.getBoundingClientRect().top - 250)
        // userRef.current.scrollIntoView()
      }
    })
  }, [postMapId, mappId])


  // assign data from previous data state into the state of which data is displayed
  useEffect(() => {
    setUsers(initialData.slice(0, size))
  }, [initialData, size])


  return (
    <div className="container-fluid">
      <ListHeader title={ props.day + "s Sprint" } byline={"Solgte lodder seneste " + props.day} background={PURPLE} icon={weekIcon} />

      {loading ? (
        <div className="container h-100 w-100" style={{}}>
          <div className="row h-100" style={{}}>
            <div className="col-12 h-100 w-100 d-flex justify-content-center align-items-center">
              <Spinner size={10}></Spinner>
            </div>
          </div>
        </div>
      ) : (
        <div className="row bg-darkGreen" ref={windowRef}style={{ paddingBottom: 100 }}>
          {users.map((item, index) => {
            if (item.mappId === mappId) {
              return (
                <div
                  ref={userRef}
                  onClick={gotoRef}
                  key={index}
                  className="col-12 bg-white d-flex justify-content-between  py-3"
                >
                  <div className="d-flex ">
                    <h3 className="text-center text-darkGreen" style={{ width: 40 }}>
                      {item.position_shared}
                    </h3>
                    <div className="ml-4 mb-0">
                      <p className="mb-n2">{item.name} </p>
                      <p className="mb-0">
                        {item.zip_code} {item.city}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center " style={{ minWidth: 40 }}>
                    <h3 className="text-darkGreen">{item.sale_count}</h3>
                  </div>
                </div>
              )
            }

            return (
              <div onClick={gotoRef} key={index} className="col-12 d-flex justify-content-between list-item py-3">
                <div className="d-flex ">
                  <h3 className="text-center " style={{ width: 40 }}>
                    {item.position_shared}
                  </h3>
                  <div className="ml-4 mb-0">
                    <p className="text-white mb-n2">{item.name} </p>
                    <p className="text-white mb-0">
                      {item.zip_code} {item.city}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-center" style={{ minWidth: 40 }}>
                  <h3 className="">{item.sale_count}</h3>
                </div>
              </div>
            )
          })}
        <div className="col-12 d-flex justify-content-center py-3">
          <div className="getMoreBtn" onClick={() => setSize(size + 20)}>HENT FLERE</div>
        </div>
        </div>
      )}
    </div>
  )
}
export default Sprint
