import React, { useEffect, useState } from 'react'
import ListHeader from '../components/ListHeader'
import { DARK_GREEN, YELLOW } from '../constants'
import { userStore } from '../store/UserStore'
import brancheIcon from '../assets/brancheWhite.svg'

const Battle = (props) => {
  const postMappId = userStore((state) => state.postMappId)
  const profile = userStore((state) => state.profile)
  const [items, setItems] = useState([])

  useEffect(() => {
    const p = '/GetBusinessRank'
    postMappId(p).then((res) => {
      setItems(res.businessBranchList)
    })
  }, [postMappId])

  return (
    <div className="container-fluid" style={{}}>
      <ListHeader
        title="Branch battle"
        byline="Spillerregistreringer i procent fordelt på branche"
        background={YELLOW}
        icon={brancheIcon}
        retailer="Branche"
        soldtickets="Permissions"
      />
      <div className="row bg-darkGreen" style={{ paddingBottom: 150 }}>
        {items.map((item, index) => {
          if (item.businessBranch === profile.businessBranch) {
            return (
              <div key={index} className="col-12 bg-white d-flex justify-content-between selected list-item">
                <div className="d-flex ">
                  <h4 className="text-center " style={{ width: 40, color: DARK_GREEN }}>
                    {item.position_shared}
                  </h4>
                  <div className="ml-4 mb-0">
                    <p className="mt-2">{item.businessBranch} </p>
                  </div>
                </div>
                <div className="d-flex justify-content-center " style={{ minWidth: 40 }}>
                  <h5 className="text-darkGreen">{item.permissionPercentage}%</h5>
                </div>
              </div>
            )
          }

          return (
            <div key={index} className="col-12 d-flex justify-content-between list-item">
              <div className="d-flex ">
                <h4 className="text-center " style={{ width: 40 }}>
                  {item.position_shared}
                </h4>
                <div className="ml-4 mb-0">
                  <p className="text-white mt-2 text-truncate" style={{ maxWidth: 230 }}>
                    {item.businessBranch}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center" style={{ minWidth: 30 }}>
                <h5 className="">{item.permissionPercentage}%</h5>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default Battle
