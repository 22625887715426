import React, { useState, useEffect } from 'react'

import { userStore } from '../../store/UserStore'
import permissionIcon from '../../assets/permission.svg'
import { post } from '../../service/API'

const ScratchScore = () => {
	const [scratcScore, setScratcScore] = useState({perctageScore:'', placement:''})
	const postMappId                    = userStore((state) => state.postMappId)
	const mappId                        = userStore((state) => state.mappId)


	useEffect(() => {
		post('/GetBusinessGlobalRank', {}).then((res) => {
			const scoreArr = res.resellerList.filter((item) => {
				return item.mappId === mappId
			})

			const score     = scoreArr.length === 1 ? scoreArr[0].permissionPercentage : 'ikke oplyst'
			const placement = scoreArr.length === 1 ? scoreArr[0].position_shared : 'ikke oplyst'
			setScratcScore({placement:placement, perctageScore:score})
		})
	}, [mappId, postMappId])

    return (
        <div className="row" style={{}}>
            <div className="col-10 bg-white mx-auto mb-2 mt-3 d-flex p-1 " style={{borderRadius:4}}>
                <img className="pl-3" src={permissionIcon} alt=""/>

                <div className="ml-3">
                    <p className="mb-0 text-darkGreen">'skrabe-score'</p>
                    <p className="mb-0 mt-n2 text-darkGreen">Din score {scratcScore.perctageScore}%</p>
                    <p className="mb-0 mt-n2 text-darkGreen">Du ligger på {scratcScore.placement}. plads</p>
                </div>
            </div>
        </div>
    )
}


export default ScratchScore