import React, { useEffect, useRef, useState } from 'react'
import '../components/list-item.scss'
import { DARK_GREEN, DARK_RED, getLeagueIcon, getLeagueTitle, LIME } from '../constants'

import ListHeader from '../components/ListHeader'
import { userStore } from '../store/UserStore'

const Liga = (props) => {
  const upDownColoring = 5
  const [players, setUsers] = useState([])
  const profile = userStore((state) => state.profile)
  const userRef = useRef(null)
  const postMappId = userStore((state) => state.postMappId)
  const mappId = userStore((state) => state.mappId)
  const leagueTitle = getLeagueTitle(profile.league_id)
  const leagueIcon = getLeagueIcon(profile.league_id)

  const gotoRef = (pos) => {
    window.scrollTo(0, pos)
  }

  useEffect(() => {
    const p = '/GetCurrentLeagueRankByReseller' //+ '?mappId=' + mappId
    postMappId(p, {}).then((res) => {
      setUsers(res.resellerList)

      if (userRef.current) {
        gotoRef(userRef.current.getBoundingClientRect().top)
      }
    })
  }, [postMappId])

  return (
    <div className="container-fluid" style={{ background: 'red' }}>
      <ListHeader
        title="Ligaplacering"
        byline={`placering i ${leagueTitle}ligaen`}
        background={DARK_RED}
        icon={leagueIcon}
      />

      <div className="row bg-darkGreen" style={{ paddingBottom: 150 }}>
        {players.map((item, index) => {
          const topThree = index < upDownColoring ? LIME : DARK_GREEN
          const bottomThree = index > players.length - 1 - upDownColoring ? 'red' : DARK_GREEN
          const bgColor = index < upDownColoring ? topThree : bottomThree

          if (item.mappId === mappId) {
            return (
              <div
                ref={userRef}
                onClick={gotoRef}
                key={index}
                className="col-12 bg-white d-flex justify-content-between selected list-item"
              >
                <div className="d-flex ">
                  <h4 className="text-center " style={{ width: 40, background: bgColor }}>
                    {item.position_shared}
                  </h4>
                  <div className="ml-4 mb-0">
                    <p className="mb-n2">{item.name} </p>
                    <p className="mb-0">
                      {item.zip_code} {item.city}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-center " style={{ minWidth: 40 }}>
                  <h3 className="text-darkGreen">{item.sale_count}</h3>
                </div>
              </div>
            )
          }

          return (
            <div onClick={gotoRef} key={index} className="col-12 d-flex justify-content-between list-item">
              <div className="d-flex ">
                <h4 className="text-center " style={{ width: 40, background: bgColor }}>
                  {item.position_shared}
                </h4>
                <div className="ml-4 mb-0">
                  <p className="text-white mb-n2">{item.name} </p>
                  <p className="text-white mb-0">
                    {item.zip_code} {item.city}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center" style={{ minWidth: 40 }}>
                <h3 className="">{item.sale_count}</h3>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default Liga
