export const API_SEARCH = 'https://peggy-olson-ai.appspot.com/search'

export const SPLASH = { title: 'Splash', to: `/`, visible: false }
export const HOME = { title: 'Home', to: `/home`, visible: false }
export const WEEKLY = { title: 'Weekly', to: `/weekly`, visible: true }
export const SPRINT = { title: 'Sprint', to: `/sprint`, visible: true }
export const LIGA = { title: 'Liga', to: `/liga`, visible: false }
export const GLOBAL_RANK = { title: 'global_rank', to: `/global_rank`, visible: true }
export const USER = { title: 'user', to: `/user`, visible: true }
export const TERMS = { title: 'terms', to: `/terms`, visible: true }
export const BATTLE = { title: 'battle', to: `/battle`, visible: true }
export const PERMISSION = { title: 'premission', to: `/permission`, visible: true }
export const MORE = { title: 'more', to: `/more`, visible: true }
export const RANK = { title: 'rank', to: '/rank', visible: true }
export const CHANCEN = { title: 'chancen', to: '/chancen', visible: true }

export const PAGES = [WEEKLY, SPRINT, LIGA, GLOBAL_RANK]
