// Package Imports
import React, { useEffect, useState } from 'react'
import { useSpring, animated } from 'react-spring'


// Local helper imports
import { userStore } from '../../store/UserStore'
// Local images/svgs & Other
import { DARK_GREEN, LIME } from '../../constants'


const GlobalRankRow = ({ mappId }) => {
	// const profile       = userStore((state) => state.profile)
    const postMappId    = userStore((state) => state.postMappId)

    const [calculated, setCalculated] = useState({ percentageScore: '0%', toAdvange: '0' })

    const [aniStyle, set] = useSpring(() => ({
        left: '0%',
        config: { friction: 20, tension: 400 },
    }))

    // /GetGlobalRank

    useEffect(() => {
        ///const leagueP = "/GetCurrentLeagueRankByReseller" // If using this, change res.round -> res.leagueRound
        const globalP = '/GetGlobalRank'
        postMappId(globalP).then((res) => {
            const users = res.resellerList
            // To calculate the no. of days between two dates
            const nextStartTime = new Date().getTime() - new Date(res.round.end_timestamp).getTime()
            var daysDiff = Math.round(Math.abs(nextStartTime / (1000 * 3600 * 24)))
            const daysToNextRound = daysDiff < 1 ? ' imorgen' : 'om ' + daysDiff + ' dage'

            const selected = users.filter((item, index) => {
                return item.mappId === mappId
            })[0]

            const userAboveArr = users.filter((item, index) => {
            return item.position_shared === selected.position_shared - 1
            })

            const userAboveSaleCount = userAboveArr.length > 0 ? userAboveArr[0].sale_count : 0
            //const userAbove = userAboveArr.length > 0 ? userAboveArr[0] : { sale_count: 0, position_shared: 0 }

            const userLastPos = users[users.length - 1].position_shared
            const mePos = Math.abs((100 / userLastPos) * selected.position_shared - 100)

            const toAdvange = userAboveSaleCount === 0 ? 0 : userAboveSaleCount - selected.sale_count
            setCalculated({ currentPos: selected.position_shared, toAdvange: toAdvange, daysToNextRound: daysToNextRound })

            set({ left: mePos + '%', delay: 1000 })
        })
    }, [postMappId, mappId, set])

    return (
        <div className="row" style={{}}>
            <div className="col-10 bg-white mx-auto  mt-3 " style={{ borderRadius: 4 }}>
                <div className="row ">
                    <div className="col-12 ">
                        <p className="text-center mt-2" style={{ fontSize: '1.1rem' }}>
                            Din Samlet Placering
                        </p>
                    </div>
                    <div className="col-12 d-flex">
                        <div className="position-relative w-100">
                            <div className="position-absolute d-flex justify-content-between w-100">
                            <SectionVertLine></SectionVertLine>
                            <SectionVertLine></SectionVertLine>
                            <SectionVertLine></SectionVertLine>
                            <SectionVertLine></SectionVertLine>
                            <SectionVertLine></SectionVertLine>
                            <SectionVertLine></SectionVertLine>
                            <SectionVertLine></SectionVertLine>
                            </div>

                            <div className="position-absolute d-flex justify-content-between w-100">
                            <HozLine color={LIME} height={10}></HozLine>
                            </div>

                            <animated.div
                                className="position-absolute "
                                style={{
                                    background: DARK_GREEN,
                                    height: 20,
                                    width: 20,
                                    borderRadius: 100,
                                    transform: ` translate3d(-5px,0px,0px)`,
                                    top: -5,
                                    zIndex: 100,
                                    ...aniStyle,
                                }}
                                ></animated.div>

                                <div className="w-100 mt-3" style={{ background: '#d6d6d6', height: 4 }}></div>

                                <div className="position-absolute d-flex justify-content-between w-100" style={{ top: 16 }}>
                                <div
                                    className=""
                                    style={{
                                    width: '16.6%',
                                    background: 'red',
                                    height: 4,
                                    }}
                                ></div>
                                <div
                                    className=""
                                    style={{
                                    width: '16.6%',
                                    background: 'green',
                                    height: 4,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mt-3">
                        {
                            calculated.toAdvange === 0 ? (
                                <p className="text-darkGreen text-center px-4 mb-0">Tillykke du ligger på 1. pladsen</p>
                            ) : (
                                <p className="text-darkGreen text-center px-4 mb-0">
                                    Du mangler kun {calculated.toAdvange} {calculated.toAdvange === 1 ? 'lod' : 'lodder'} fra at ramme{' '}
                                    {calculated.currentPos - 1}. pladsen
                                </p>
                            )
                        }
                        <p className="text-center mb-3 font-weight-bold" style={{ color: 'red' }}>
                            {/* Næste runde starter <u>{calculated.daysToNextRound}</u> */}
                        </p>
                    </div>


                </div>
            </div>
        </div>
    )
}


const HozLine = ({ color, zIndex = 0, height = 10, width = '100%', borderRadius = 20 }) => {
    return (
      <div
        className="position-absolute "
        style={{ zIndex: zIndex, width: width, background: color, height: height, borderRadius: borderRadius }}
      ></div>
    )
}
  
const SectionVertLine = () => {
    return <div style={{ background: '#a9a9a9', zIndex: 1, height: 20, width: 1 }}></div>
}

export default GlobalRankRow