import React from 'react'
import { Redirect, Route } from 'react-router'
import { userStore } from '../store/UserStore'
import { SPLASH } from '../routes'

const HasProfileRoute = ({ children, ...rest }) => {
  const mappId = userStore((state) => state.mappId)

  if (!mappId) {
    console.error('No mappId found - sending user to splash to load profile')
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        mappId ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: SPLASH.to,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
export default HasProfileRoute

/*const HasProfileRoute = ({ children, ...rest }) => {

	const isAuth = true

	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAuth ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/splash",
							state: { from: location }
						}}
					/>
				)
			}
		/>
	)
}
export HasProfileRoute*/
