import React, { useEffect, useState } from 'react'
import Title from '../Title'
//import { currentUser } from '../../injectedState'

import pokalSvg from '../../assets/pokal.svg'
import { useLocation } from 'react-router'

import { userStore } from '../../store/UserStore'
import Spinner from '../spinner/Spinner'

const Splash = ({ retailer = 'Byens Grill og Kiosk', campaignActive = false, startDate, endDate }) => {
  const location = useLocation()

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const hasMappId = location.search && location.search.length > 1 ? location.search.indexOf('id') : null

  const hasValue = hasMappId ? location.search.split('=') : null
  const mappIdFromUrl = hasValue && hasValue.length === 2 ? hasValue[1] : null

  const profile = userStore((state) => state.profile)
  const getUserFromMappId = userStore((state) => state.getUserFromMappId)

  const monthNames = ["Januar", "Februar", "Marts", "April", "Maj", "Juni",
  "Juli", "August", "September", "Oktober", "November", "December"];

  useEffect(() => {
    setLoading(true)

    // console.log(' Splash > mappIdFromUrl = ', mappIdFromUrl)
    getUserFromMappId(mappIdFromUrl)
      .then((res) => {
        setLoading(false)
      })
      .catch((err) => {
        setError(true)
      })
  }, [getUserFromMappId, mappIdFromUrl])

  window.dataLayer.push({
    mappId: profile.mappId ? profile.mappId : 'guest',
    event: 'visitedPage',
    page: 'Splash',
  })

  if (error) {
    // console.error(' Splash > User could mot be found = ')
    return (
      <div className="container h-100 w-100" style={{}}>
        <div className="row" style={{}}>
          <div className="col-8 mx-auto">
            <h4 className="text-center mt-4">Vi kan desværre ikke finde din bruger</h4>
          </div>
        </div>
      </div>
    )
  }

  if (profile) {
    window.dataLayer.push({
      mappId: profile ? profile.mappId : 'guest',
      event: 'visitedPage',
      page: 'Home',
    })
  }

  return (
    <>
      {loading ? (
        <div className="container h-100 w-100" style={{}}>
          <div className="row h-100" style={{}}>
            <div className="col-12 h-100 w-100 d-flex justify-content-center align-items-center">
              <Spinner size={10}></Spinner>
            </div>
          </div>
        </div>
      ) : (
        <div className="container h-100 w-100" style={{}}>
          <div className="row " style={{ height: '20%' }}>
            <div className="col-12 h-100 d-flex justify-center align-items-end">
              <Title />
            </div>
          </div>

          <div className="row " style={{ height: '40%' }}>
            <div className="col-12 h-100 ">
              <div
                className="h-100 d-flex justify-center"
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: 'url(' + pokalSvg + ') ',
                  backgroundPosition: 'center center',
                }}
              ></div>
            </div>
          </div>
          {
            campaignActive ?
            // If campaign active, show normal text and navbar
              (
                <div className="row" style={{ height: '30%' }}>
                  <div className="col-12 mt-3 mx-auto">
                    <h4 className="text-center">
                      Er du klar til at se, hvordan <p className="mb-0 text-yellow">{profile.name}</p> klarer sig?
                    </h4>
                  </div>
                </div>
              )
            :
            // If campaign NOT active, show new text and no navbar
            // The date in the text should not be hardcoded.
              (
                <div className="row" style={{ height: '30%' }}>
                  <div className="col-12 mt-3 mx-auto">
                    <h4 className="text-center">
                      Kom tilbage igen når Millionærligaen går live den { startDate !== undefined ? startDate.getDate() + '. ' + monthNames[startDate.getMonth()] : null }!
                    </h4>
                  </div>
                </div>
              )
          }
          {/* <div className="row" style={{ height: '30%' }}>
            <div className="col-12 mt-3 mx-auto">
              <h4 className="text-center">
                Er du klar til at se, hvordan <p className="mb-0 text-yellow">{profile.name}</p> klarer sig?
              </h4>
            </div>
          </div> */}
        </div>
      )}
    </>
  )
}

export default React.memo(Splash)
