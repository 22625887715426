import React, { useEffect, useState } from 'react'
import ListHeader from '../components/ListHeader'
import { post } from '../service/API'
import { userStore } from '../store/UserStore'
import permissionIcon from '../assets/permissionWhite.svg'

const PermissionScore = (props) => {
  const mappId = userStore((state) => state.mappId)
  const postMappId = userStore((state) => state.postMappId)

  const [selected, setSelected] = useState({ totalPermissions: '-', position_shared: '-' })
  const [business, setBusiness] = useState({ position_shared: '-' })
  //const [error, setError] = useState(null)

  useEffect(() => {
    post('/GetBusinessGlobalRank').then((res) => {
      const filtered = res.resellerList.filter((item) => {
        return item.mappId === mappId
      })

      if (filtered.length === 1) {
        setSelected(filtered[0])
      } else {
        //setError(true)
      }
    })
  }, [mappId, postMappId])

  useEffect(() => {
    postMappId('/GetCurrentBusinessRankByReseller').then((res) => {
      console.log(' PermissionScore > buss = ', res)
      const filtered = res.resellerList.filter((item) => {
        return item.mappId === mappId
      })

      console.log(' PermissionScore > filtered = ', filtered)
      if (filtered.length === 1) {
        setBusiness(filtered[0])
      }
    })
  }, [mappId, postMappId])

  console.log(' PermissionScore > selected = ', selected)

  return (
    <div className="container-fluid h-100">
      <ListHeader
        showListLabels={false}
        title="Din 'skrabe-score'"
        byline="Se hvor godt du ligger"
        icon={permissionIcon}
        background={'#E29C00'}
      />
      <div className="row ">
        <div className="col-10 mx-auto">
          <div className="row bg-white mt-4" style={{ borderRadius: 4 }}>
            <div className="col-12">
              <h1 className="text-green mt-1 display-4 mb-1  font-weight-bold text-center">
                {selected.totalPermissions}
              </h1>
            </div>
            <div className="col-10 mx-auto">
              <h5 className="text-darkGreen mb-4 text-center" style={{ lineHeight: '1' }}>
                af dine kunder har sagt ja til at modtage vores nyhedsbrev
              </h5>
            </div>
          </div>
        </div>

        {}
        <div className="col-10 mx-auto">
          <div className="row bg-white mt-4" style={{ borderRadius: 4 }}>
            <div className="col-12">
              <h5 className="text-darkGreen mt-3 mb-0 text-center">Din totalplacering</h5>
              <h4 className="text-green font-weight-bold text-center mt-0">{selected.position_shared}</h4>
            </div>
            <div className="col-12">
              <h5 className="mt-2 mb-0  text-center" style={{ color: '#094400' }}>
                Placering i din branche
              </h5>
              <h5 className="text-green mb-3 font-weight-bold text-center">{business.position_shared}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PermissionScore
