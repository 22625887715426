// Package Imports
import React, { useState, useEffect } from 'react'


// Local helper imports
import { userStore } from '../../store/UserStore'
// Local images/svgs
import { ReactComponent as StarIcon } from '../../assets/star.svg'


const FiveInARow = (props) => {
	const profile                   = userStore((state) => state.profile)
    const [streak, setStreak]       = useState([])
    const [daysLeft, setDaysLeft]   = useState({days:0, total:5})
	const postMappId                = userStore((state) => state.postMappId)
	// const mappId                    = userStore((state) => state.mappId)

	useEffect(() => {
		postMappId('/GetCurrentResellerStreakState').then((res) => {
			const count = res.streak_state.filter((item) => {
				return item.highlight
			})
			setDaysLeft({days:count.length, total:res.streak_state.length})
			setStreak(res.streak_state)
		})
	}, [postMappId])
    


    return (
        <div className="row" style={{}}>
            <div className="col-10 bg-white mx-auto mb-2 mt-2 py-2 px-1 " style={{borderRadius:4}}>
                <p className="mb-1 text-darkGreen text-center p-big  ">5 på stribe!</p>

                {daysLeft.total === daysLeft.days && (
                    <p className="mb-0 mt-n2 text-darkGreen text-center px-2">
                        Du er med i <i>lodtrækningen om {profile.price} kr.</i>
                    </p>
                )}

                {daysLeft.total === daysLeft.days + 1 ? (
                    <p className="mb-0 mt-n2 text-darkGreen text-center px-2">
                        Sælger du lodder {daysLeft.total - daysLeft.days} dag mere, er du med
                        <i>lodtrækningen om {profile.price} kr.</i>
                    </p>
                ) : (
                    <p className="mb-0 mt-n2 text-darkGreen text-center px-2">
                        Sælger du lodder de næste {daysLeft.total - daysLeft.days} dage, er du med i{' '}
                        <i>lodtrækningen om {profile.price} kr.</i>
                    </p>
                )}

                <div className="d-flex mt-2 justify-content-center">
                    {streak.map((item, index) => {
                        const color = item.highlight ? '#ffa200' : '#bababa'

                        return (
                            <div key={index} className="mx-1">
                                <StarIcon style={{fill:color, width:35, height:35}}></StarIcon>
                                <p className="text-center text-darkGreen font-weight-bold">{item.dayNameShort}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default FiveInARow