import React, { useState } from 'react'
import axios from 'axios'

export default function Signup({ email, setSignedUp }) {
  const requestStateEnum = Object.freeze({
    pending: 1,
    isPosting: 2,
    hasPosted: 3,
    alreadySignedUp: 4,
  })

  const [requestState, setRequestState] = useState(requestStateEnum.pending)
  const [mobil, setMobil] = useState('')
  const [showError, setShowError] = useState(false)
  const apiKey = "48d22828-1e04-47ec-b04e-44e82395f2cf"

  const signup = async e => {
    e.preventDefault()
    setShowError(false)
    setRequestState(requestStateEnum.pending)

    if (mobil.length !== 8) {
      setShowError(true)
      return
    }
    
    // Email is actually not an email, it is a MappID.
    try {
      const { status } = await axios.get(`https://vlml.ambitioncloud.dk/AmbitionKonkurrenceTilmeld?emailid=${email}&mobil=${mobil}&key=${apiKey}`, {
        headers: {
          "Content-Type": "text/html",
          "X-ApiKey": "6RkmiveGfQv82CU"
        }
      })

      if(status === 208){
        setRequestState(requestStateEnum.alreadySignedUp)
        setSignedUp(true)
      }else {
        setRequestState(requestStateEnum.hasPosted)
        setSignedUp(true)
      }
      
    } catch (error) {
      console.log(error)
      alert('Noget gik galt. Prøv igen senere.')
      setRequestState(requestStateEnum.pending)
    }

  }

  if (requestState === requestStateEnum.alreadySignedUp) {
    return (
      <h2 className="text-5xl">Du er allerede tilmeldt konkurrencen</h2>
    )
  }

  if (requestState === requestStateEnum.hasPosted) {
    return (
      <h2 className="text-5xl">Tak for din tilmelding. Held og lykke!</h2>
    )
  }

  if (requestState === requestStateEnum.isPosting) {
    return (
      <h2 className="text-5xl">Afventer svar...</h2>
    )
  }

  const errStyles = "text-red text-xs font-bold"
  return (
    <form onSubmit={signup}
      className="shadow-lg border border-solid border-grey p-4 rounded text-black max-w-sm mt-4"
    >
      <div className="flex justify-between my-2">
        <input value={mobil} onChange={e => setMobil(e.target.value)} type="tel" name="mobil" id="mobil" min="8" max="8" placeholder="Mobil telefonnummer" className="mr-2 p-2 border rounded border-solid border-grey outline-none flex-grow" />
        <input type="submit" value="Tilmeld" className="hover:bg-green bg-darkGreen text-white font-bold py-2 px-4 rounded p-2 pointer" />
      </div>

      <p className={showError ? errStyles : errStyles + " opacity-0"}>Det skal være et dansk telefonnummer på 8 cifre uden +45</p>

    </form>
  )
}