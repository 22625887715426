// Package Imports
import React from 'react'


// Local helper imports
// import { userStore } from '../store/UserStore'
// Local Component Imports
import ListHeader from '../components/ListHeader'
import ChancenRow from './user/ChancenRow'
// Local images/svgs
import starWhite from '../assets/star_white.svg'
import { DARK_GREEN } from '../constants'


const Chancen = () => {

    return (
        <div className="container-fluid h-100">
            <ListHeader 
                title="Chancen"
                byline="Sælg lodder 3 dage i streg"
                icon={starWhite}
                background={'#baea00'}
				textColor={DARK_GREEN}
				showListLabels={false}
            />
			<ChancenRow />
			
        </div>
    )
}

export default Chancen