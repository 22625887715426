import React, { useRef, useEffect } from 'react'
import { DARK_GREEN, LIME } from '../constants'
import { userStore } from '../store/UserStore'

const List = ({ items, highListPlacement = false }) => {
  const userRef = useRef(null)
  const mappId = userStore((state) => state.mappId)

  const gotoRef = (pos) => {
    window.scrollTo(0, pos)
  }

  useEffect(() => {
    if (userRef.current) {
      gotoRef(userRef.current.getBoundingClientRect().top - 250)
    }
    
  }, [])

  return (
    <>
      {items.map((item, index) => {
        const topThree = index < 3 ? LIME : DARK_GREEN
        const bottomThree = index > items.length - 3 ? 'red' : DARK_GREEN
        const setBgColor = index < 3 ? topThree : bottomThree
        const bgColor = highListPlacement ? setBgColor : 'none'

        if (item.mappId === mappId) {
          return (
            <div
              ref={userRef}
              key={index}
              className="col-12 bg-white d-flex justify-content-between selected list-item"
            >
              <div className="d-flex ">
                <h4
                  className="text-center "
                  style={{ width: 40, background: bgColor, color: bgColor === 'none' ? DARK_GREEN : 'white' }}
                >
                  {item.position_shared}
                </h4>
                <div className="ml-4 mb-0">
                  <p className="mb-n2">{item.name} </p>
                  <p className="mb-0">
                    {item.zip_code} {item.city}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center " style={{ minWidth: 40 }}>
                <h3 className="text-darkGreen">{item.sale_count}</h3>
              </div>
            </div>
          )
        }

        return (
          <div key={index} className="col-12 d-flex justify-content-between list-item">
            <div className="d-flex ">
              <h4 className="text-center " style={{ width: 40, background: bgColor }}>
                {item.position_shared}
              </h4>
              <div className="ml-4 mb-0">
                <p className="text-white mb-n2">{item.name} </p>
                <p className="text-white mb-0">
                  {item.zip_code} {item.city}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center" style={{ minWidth: 40 }}>
              <h3 className="">{item.sale_count}</h3>
            </div>
          </div>
        )
      })}
    </>
  )
}
export default List
