import React, { useEffect, useState } from 'react'
import Title from '../Title'

import ugensSvg from '../../assets/ugens.svg'
import fredagssprintSvg from '../../assets/fredagssprint.svg'
import starWhite from '../../assets/star_white.svg'

import xtra from '../../assets/xtra.svg'
import placementIcon from '../../assets/placeringWhite.svg'
import permissionIcon from '../../assets/permissionWhite.svg'
import brancheIcon from '../../assets/brancheWhite.svg'

import { Link } from 'react-router-dom'
import { GLOBAL_RANK, BATTLE, PERMISSION, LIGA, MORE, SPRINT, WEEKLY, CHANCEN } from '../../routes'
import { userStore } from '../../store/UserStore'
import { getLeagueIcon } from '../../constants'
// import { getLeagueTitle } from '../../constants'


const Home = () => {
	const profile = userStore((state) => state.profile)
    const postMappId = userStore((state) => state.postMappId)
    // const mappId = userStore((state) => state.mappId)

    const leagueIcon = getLeagueIcon(profile.league_id)
    // const ligaType = getLeagueTitle(profile.league_id)
    // const ligaTitle = getLeagueTitle(profile.league_id)

    const [uiConfig, setUiConfig] = useState([])



    useEffect(() => {
        let colors = ["bg-darkGreen", "bg-purple", "bg-red", "bg-orange"]
        postMappId('/EnumUIElements').then((res) => {
            // console.log('EnumUIElem:', res.UIElementList)
            // setUiConfig(res.UIElementList)
            let mainElements = []
            res.UIElementList.map((item, index) => {
            //   if (item.Id.startsWith('Main.')) {
            //     mainElements.push(item)
            //   }
              if (item.Id.startsWith('Main.') && item.Active === true) {
                mainElements.push(item)
              }
              return null
            })
            for (let i = 0; i < mainElements.length; i+=4) {
              // console.log('1,4,8,12...:', mainElements[i])
              mainElements[i].btnColor = colors[0]
            }
            for (let i = 1; i < mainElements.length; i+=4) {
              // console.log('2,5,9,13...:', mainElements[i])
              mainElements[i].btnColor = colors[1]
            }
            for (let i = 2; i < mainElements.length; i+=4) {
              // console.log('3,6,10,14...:', mainElements[i])
              mainElements[i].btnColor = colors[2]
            }
            for (let i = 3; i < mainElements.length; i+=4) {
              // console.log('4,7,11,15...:', mainElements[i])
              mainElements[i].btnColor = colors[3]
            }
            // console.log('Main Elements:', mainElements)
            setUiConfig(mainElements)
        })
    }, [postMappId])

    // useEffect(() => {
    //     console.log('UICONFIG VARIABLE SET!!!!')
    // }, [uiConfig])


    const getUIButton = (item, index) => {
        let routePath = "/home"
        let imgSrc = ugensSvg
        let text = item.DisplayText
        let styleClass = "square rounded shadow-sm " + item.btnColor

        switch(item.Id) {
            case "Main.WeekHighJumper":
                routePath = WEEKLY.to
                imgSrc = ugensSvg
                // styleClass = styleClass + "bg-darkGreen"
                break
            case "Main.Sprint":
                routePath = SPRINT.to
                imgSrc = fredagssprintSvg
                // styleClass = styleClass + "bg-purple"
                break
            case "Main.LeagueRank":
                routePath = LIGA.to
                imgSrc = leagueIcon
                // styleClass = styleClass + "bg-red"
                break
            case "Main.ChanceStreak":
                routePath = CHANCEN.to
                imgSrc = starWhite
                // styleClass = styleClass + "bg-red"
                break
            case "Main.GlobalRank":
                routePath = GLOBAL_RANK.to
                imgSrc = placementIcon
                // styleClass = styleClass + "bg-orange"
                break
            case "Main.BusinessRank":
                routePath = BATTLE.to
                imgSrc = brancheIcon
                // styleClass = styleClass + "bg-darkGreen"
                break
            case "Main.PermissionRank":
                routePath = PERMISSION.to
                imgSrc = permissionIcon
                // styleClass = styleClass + "bg-darkGreen"
                break
            case "Main.MoreGames":
                routePath = MORE.to
                imgSrc = xtra
                // styleClass = styleClass + "bg-orange"
                break
          
            default:
              console.log('Default case in Home Switch Case')
        }

        return (
            <Link to={routePath} className={styleClass} key={item.SortOrder}>
                <div className="square__content p-3 d-flex flex-column justify-content-center align-items-center flex-col">
                    <img className="w-100 h-12 pt-2" src={imgSrc} alt={text} />
                    <span className="mt-3 text-white">{ text }</span>
                </div>
            </Link>
        )
    }


    return (
        <div className="max-w-xs mx-auto">
            <Title />
            <div className="d-flex justify-content-center flex-wrap text-center p-4 mx-auto " style={{ maxWidth: 500 }}>
                {
                  uiConfig.map((item, index) => {
                    // console.log('ShowUIElements:', item)
                    // getUIButton(item)
                    return getUIButton(item, index)
                  })
                }
                {/* <Link to={WEEKLY.to} className="square rounded shadow-sm bg-darkGreen">
                    <div className="square__content p-3 d-flex flex-column justify-content-center align-items-center flex-col">
                        <img className="w-100 h-12 pt-2" src={ugensSvg} alt="Ugens Højdespringer" />
                        <span className="mt-3 text-white">Ugens Højdespringer</span>
                    </div>
                </Link>

                <Link to={SPRINT.to} className="square rounded shadow-sm bg-purple">
                    <div className="square__content p-3 d-flex flex-column justify-content-center align-items-center flex-col">
                        <img className="w-100 h-12" src={fredagssprintSvg} alt="Torsdags Sprint" />
                        <span className="mt-4 text-white">Torsdags Sprint</span>
                    </div>
                </Link>

                <Link to={CHANCEN.to} className="square rounded shadow-sm bg-red">
                    <div className="square__content p-3 d-flex flex-column justify-content-center align-items-center flex-col">
                        <img className="w-100 h-12 pt-2" src={starWhite} alt="Lokal Ligaen" />
                        <span className="mt-3 text-white">Chancen</span>
                    </div>
                </Link>

                <Link to={RANK.to} className="square rounded shadow-sm bg-orange">
                    <div className="square__content p-8 d-flex flex-column justify-content-center align-items-center flex-col">
                        <img className="w-100 h-12 pt-2" src={stairsWhite} alt="Samlet stilling" />
                        <span className="mt-3 text-white">
                            Samlet stilling
                        </span>
                    </div>
                </Link> */}
            </div>
        </div>
    )
}

export default React.memo(Home)
