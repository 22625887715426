import React, { useEffect, useState } from 'react'
import ListHeader from '../components/ListHeader'
import { YELLOW } from '../constants'

import List from '../components/List'
import { userStore } from '../store/UserStore'
// import currentWeekNumber from 'current-week-number'
import placementIcon from '../assets/placeringWhite.svg'
import Spinner from '../components/spinner/Spinner'

const GlobalRank = (props) => {
  const postMappId = userStore((state) => state.postMappId)
  const mappId = userStore((state) => state.mappId)
  const [items, setItems] = useState([])
  const [initialData, setInitialData] = useState([])
  const [size, setSize] = useState(20)
  const [loading, setLoading] = useState(false)
  
  // const weekNum = currentWeekNumber() || ''
  

  useEffect(() => {
    setLoading(true)

    const p = '/GetGlobalRank'
    postMappId(p).then((res) => {
      // eslint-disable-next-line array-callback-return
      res.resellerList.map((item, index) => {
        if(item.mappId === mappId) return setSize(index+15)
      })

      setInitialData(res.resellerList)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postMappId])
  
  
  // assign data from previous data state into the state of which data is displayed
  useEffect(() => {
     setItems(initialData.slice(0, size))
  }, [initialData, size])


  return (
    <div className="container-fluid">
      <ListHeader
        title="Samlet stilling"
        byline={ (<br/>) }
        background={YELLOW}
        icon={placementIcon}
      />
  
      {loading ? (
        <div className="container h-100 w-100" style={{}}>
          <div className="row h-100" style={{}}>
            <div className="col-12 h-100 w-100 d-flex justify-content-center align-items-center">
              <Spinner size={10}></Spinner>
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className="row bg-darkGreen"  style={{ paddingBottom: 150 }}>
            <List items={items}></List>
            <div className="col-12 d-flex justify-content-center py-3">
              <div className="getMoreBtn" onClick={() => setSize(size + 20)}>HENT FLERE</div>
            </div>
          </div>
       </React.Fragment>
      )}
    </div>
  )
}
export default GlobalRank
