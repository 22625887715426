import create from 'zustand'
import { post } from '../service/API'

const mappKey = 'mappId' // skal ændres til kollId
const kollKey = 'kollId' // skal ændres til kollId

const getToken = (key) => {
  const token = window.localStorage.getItem(key)
  return token
}

const setToken = (value, key) => {
  window.localStorage.setItem(key, value)
}

export const userStore = create((set, get) => {
  return {
    mappId: null,
    profile: { league_id: '', businessBranch: '', city: '', koll: '', mappId: '', name: '', zip_code: '', price: '' },

    getUserFromMappId: (kollId) => {
      const id = kollId ? kollId : getToken(kollKey)
      // console.log(' UserStore > id = ', id)

      const p = `/GetResellerBasicsData?koll=${id}` //'/GetResellerBasicsData' + '?mappId=' + id // + '0000000000'
      return post(p)
        .then((res) => {
          const mappId = res.mappId
          const userKollID = res.koll
          setToken(mappId, mappKey)
          setToken(userKollID, kollKey)

          set({ mappId: mappId })
          set({ profile: res })
          return res
        })
        .catch((err) => {
          console.log(' User > err = ', err)
          return Promise.reject(err)
        })
    },

    // created as an easy way to get and post the mappId to any endpoint
    postMappId: (endpoint) => {
      const p = endpoint + '?mappId=' + getToken(mappKey)
      return post(p)
    },
  }
})
